<template>
	<div class="headerg">
		<div
			class="pd_header_pc flex align_items_c"
			:class="{ headergscrolled: true }"
			@mouseleave="showLanguageNew(false)"
		>
			<div
				class="tab_logo pointer"
				@click="
					$router.push({
						path: '/',
					})
				"
			>
				<img
					src="https://res.metaera.hk/resources/assets/images/logo_w.png"
					alt=""
				/>
			</div>
			<div class="align_items_c justify_content_sb tab_action flex">
				<div class="navList_first_pd flex align_items_c">
					<div
						class="tab_actionItem flex pointer"
						v-for="(item, index) in navList"
						:key="index"
						@click="toPath(item, index)"
						:class="{ active: currentPath == item.path }"
					>
						<span>{{ item.name }}</span>
						<div class="tab_actionItem_tag" v-if="item.name == '2025'"
							><span>new</span></div
						>
						<div class="tab_actionItem_line"></div>
					</div>

					<div
						class="tab_actionItem flex pointer"
						@mouseenter="showLuntanNew(true)"
					>
						<span @click="showLuntanNew(true)">历届论坛</span>
						<div
							class="top_luntan_list"
							@mouseenter="showLuntanNew(true)"
							@mouseleave="showLuntanNew(false)"
							v-if="showLuntan == 1"
						>
							<div
								class="top_luntan_list_item pointer"
								@click="showUrl('https://metaera.hk/CryptoFi2023')"
								>2023加密金融论坛</div
							>
							<div
								class="top_luntan_list_item pointer"
								@click="showUrl('https://metaera.hk/CryptoFi2024')"
								>2024加密金融论坛</div
							>
						</div>
					</div>
				</div>

				<div
					class="tab_fun_language"
					:class="{ tab_fun_language_white: this.type == 'white' }"
					@mouseenter="showLanguageNew(true)"
				>
					<div class="tab_fun_language_item pointer" @click="showLanguage()">
						{{
							this.localLanguage == 0
								? "简"
								: this.localLanguage == 1
								? "繁"
								: "EN"
						}}
					</div>

					<div
						class="top_Language_list"
						@mouseenter="showLanguageNew(true)"
						@mouseleave="showLanguageNew(false)"
						:class="{ top_Language_list_white: this.type == 'white' }"
						v-if="showSel == 1"
					>
						<div
							class="top_Language_list_item pointer"
							@click="selLanguage(0)"
							:class="{ top_Language_list_item_white: this.type == 'white' }"
							>简体中文</div
						>
						<div
							class="top_Language_list_item pointer"
							@click="selLanguage(1)"
							:class="{ top_Language_list_item_white: this.type == 'white' }"
							>繁体中文</div
						>
						<div
							class="top_Language_list_item pointer"
							@click="selLanguage(2)"
							:class="{ top_Language_list_item_white: this.type == 'white' }"
							>English</div
						>
					</div>
				</div>
			</div>
		</div>
		<div
			class="pd_header_mobile flex align_items_c"
			:class="{ headergscrolled: true }"
		>
			<div class="tab_btn">
				<img
					src="https://res.metaera.hk/resources/assets/images/event/48.png"
					@click="showList()"
					alt=""
				/>
			</div>
			<div class="tab_logo">
				<img
					src="https://res.metaera.hk/resources/assets/images/logo_w.png"
					@click="goHome()"
					alt=""
				/>
			</div>
		</div>

		<div class="pd_header_list" v-show="isShowList">
			<div class="pd_header_list_btn" @click="hideList()">
				<img
					src="https://res.metaera.hk/resources/assets/images/event/42.png"
					alt=""
				/>
			</div>
			<div class="tab_actionItem_line_2"></div>
			<div class="pd_header_list_1">
				<div
					class="tab_actionItem_pd flex pointer"
					v-for="(item, index) in navList"
					:key="index"
					@click="toPath(item, index)"
					:class="{ active: currentPath == index }"
				>
					<span>{{ item.name }}</span>
					<div class="tab_actionItem_line_1"></div>
					<div class="tab_actionItem_line_3"></div>
				</div>
				<div
					class="tab_actionItem_pd flex pointer"
					@click="showUrl('https://metaera.hk/CryptoFi2024')"
				>
					<span>2024</span>
					<div class="tab_actionItem_line_1"></div>
					<div class="tab_actionItem_line_3"></div>
				</div>
				<div
					class="tab_actionItem_pd flex pointer"
					@click="showUrl('https://metaera.hk/CryptoFi2023')"
				>
					<span>2023</span>
					<div class="tab_actionItem_line_1"></div>
					<div class="tab_actionItem_line_3"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { number } from "echarts";
export default {
	name: "",
	props: {
		currentPath: "",
	},
	created() {},

	data() {
		return {
			currentIndex: 0,
			isShowList: false,

			mobile: "",

			currentIndex: 0,
			isShowList: false,
			localLanguage: 0,
			showSel: 1,
			showLuntan: 0,
			navList: [
				{
					name: "首页",
					path: "CryptoFi",
				},
				{
					name: "合作伙伴",
					path: "CryptoFiPartner",
				},
				{
					name: "演讲嘉宾",
					path: "CryptoFiSpeaker",
				},
				{
					name: "活动议程",
					path: "CryptoTime",
				},
				{
					name: "联系我们",
					path: "CryptoFiContact",
				},
			],
		};
	},
	mounted() {
		console.log("------------------location.href", location.href);
		this.showSel = 0;
		let localLanguage = sessionStorage.getItem("localLanguage");
		if (localLanguage == "繁体中文") {
			this.localLanguage = 1;
		} else if (localLanguage == "english") {
			this.localLanguage = 2;
		} else {
			this.localLanguage = 0;
		}
		// console.log(localLanguage)
		// 包含关系
		if (location.href.indexOf("news") != -1) {
			sessionStorage.setItem("localLanguageHK", "");
			this.currentIndex = 1;
		} else if (location.href.indexOf("columns") != -1) {
			sessionStorage.setItem("localLanguageHK", "");
			this.currentIndex = 2;
		} else if (
			location.href.indexOf("activity") != -1 ||
			location.href.indexOf("events") != -1
		) {
			sessionStorage.setItem("localLanguageHK", "");
			this.currentIndex = 3;
		} else if (location.href.indexOf("/hk") != -1) {
			this.currentIndex = 5;
			this.selHKLanguage();
		} else if (location.href.indexOf("/usa") != -1) {
			this.currentIndex = 4;
		} else if (
			location.href.indexOf("/") == -1 ||
			location.href.indexOf("home") != -1 ||
			!location.href.pathname
		) {
			sessionStorage.setItem("localLanguageHK", "");
			this.currentIndex = 0;
			sessionStorage.setItem("localLanguageHK", "");
			this.currentIndex = 999;
		}
	},
	methods: {
		toPath(item, index) {
			console.log(index);
			this.currentIndex = index;
			this.$router.push({ path: item.path });
			this.isShowList = false;
		},
		goHome() {
			this.$router.push({
				path: "/",
			});
		},
		showList() {
			this.isShowList = true;
		},
		hideList() {
			this.isShowList = false;
		},
		showUrl(url) {
			window.open(url, "");
		},
		changeImage(imageKey) {
			this.currentImage = this.images[imageKey];
		},
		showLanguageNew(show) {
			this.showSel = show ? 1 : 0;
			if (!show) {
				this.showLuntan = 0;
			}
		},
		showLuntanNew(show) {
			this.showLuntan = show ? 1 : 0;
			if (!show) {
				this.showSel = 0;
			}
		},
		showLanguage() {
			this.showSel = this.showSel == 1 ? 0 : 1;
		},
		selHKLanguage() {
			const index = 1;
			this.showSel = 0;
			this.localLanguage = index;
			sessionStorage.setItem("localLanguageHK", "繁体中文");
			if (this.localLanguage == 0) {
				this.$s2t(document.body);
			} else {
				this.$e2s(document.body, 1);
			}
			// 通知发送
			newBus.$emit("global-notification", {
				localLanguage: this.localLanguage,
			});
		},
		selLanguage(index) {
			this.showSel = 0;
			// console.log("selLanguage");
			// console.log(index);

			if (index == 0) {
				sessionStorage.setItem("localLanguage", "简体中文");
				if (location.href.indexOf("/hk") != -1) {
					sessionStorage.setItem("localLanguageHK", "简体中文");
				} else {
					sessionStorage.setItem("localLanguageHK", "");
				}

				if (this.localLanguage == 1) {
					this.$t2s(document.body);
				} else {
					this.$e2s(document.body, 0);
				}
			} else if (index == 1) {
				sessionStorage.setItem("localLanguage", "繁体中文");
				if (location.href.indexOf("/hk") != -1) {
					sessionStorage.setItem("localLanguageHK", "繁体中文");
				} else {
					sessionStorage.setItem("localLanguageHK", "");
				}
				if (this.localLanguage == 0) {
					this.$s2t(document.body);
				} else {
					this.$e2s(document.body, 1);
				}
			} else {
				sessionStorage.setItem("localLanguage", "english");
				if (location.href.indexOf("/hk") != -1) {
					sessionStorage.setItem("localLanguageHK", "english");
				} else {
					sessionStorage.setItem("localLanguageHK", "");
				}
				this.$s2e(document.body);
			}
			this.localLanguage = index;
			// 通知发送
			newBus.$emit("global-notification", {
				localLanguage: this.localLanguage,
			});
		},
	},
	watch: {
		"$route.name": function (n) {
			console.log("path", n);

			this.showHeader = n == "/";
			this.currentPath = n;
		},
	},
};
</script>

<style scoped>
.headergscrolled {
	background-color: #4930ce !important;
	color: white;
}
.pd_header_mobile {
	display: none;
}
.pd_header_pc {
	display: flex;
	height: 80px;
	top: 0px;
	left: 0;
	width: calc(100vw);
	margin: 0 auto;
	z-index: 100;
	position: fixed;
	background: #1e1e1e;
}
.tab_actionItem_tag {
	position: absolute;
	top: -13px;
	right: -26px;
	z-index: 2;
	opacity: 1;
}

.tab_actionItem_tag span {
	background: #f63535;
	padding-left: 2px;
	padding-right: 2px;
	padding-bottom: 2px;
	width: 20px;
	height: 14px;
	font-size: 10px;
	font-weight: 400 !important;
	display: inline-block;
	text-align: center;
	align-content: center;
	align-items: center;
	border-radius: 6px;
}
.tab_action {
	position: absolute;
	right: 150px;
}
.tab_actionItem {
	position: relative;
	width: auto;
	margin-left: 40px;
	margin-top: 20px;
	height: 50px;
	display: flex;
	flex-direction: column;
	display: flex;
	align-content: center;
	align-items: center;
	text-align: center;
}
.tab_actionItem span {
	font-weight: normal;
	color: white;
}

.tab_actionItem .tab_actionItem_line {
	display: none;
}
.header_buyBtn {
	margin-left: 40px;
	border-radius: 10px;
	padding-left: 20px;
	padding-right: 20px;
	padding-top: 10px;
	padding-bottom: 10px;

	opacity: 1;
	background: #6429ff;
	font-size: 16px;
	font-weight: bold;
	line-height: 16px;
	display: flex;
	align-items: center;
	text-transform: uppercase;
	letter-spacing: 0.53px;
	font-variation-settings: "opsz" auto;
	color: #ffffff;
}

.navList_first_pd .active span {
	font-weight: bold;
}

.navList_first_pd .active .tab_actionItem_line {
	display: flex;
	align-content: center;
	align-items: center;
	text-align: center;
	margin-top: 10px;
	width: 50px;
	height: 3px;
	background: white;
}
.tab_logo {
	margin-left: 10%;
}

.tab_logo img {
	height: 30px;
}

.tab_btn {
	position: absolute;
	right: 50px;
}

.tab_btn img {
	height: 45px;
}
</style>

<style scoped>
@media screen and (max-width: 900px) {
	.headergscrolled {
		background-color: #333333 !important;
	}
	.pd_header_mobile {
		display: flex;
		height: 50px;
		top: 0px;
		left: 0;
		width: calc(100vw);
		margin: 0 auto;
		z-index: 100;
		position: fixed;
	}
	.pd_header_pc {
		display: none;
	}

	.tab_logo {
		margin-left: -0px;
		width: 100%;
		text-align: center;
	}

	.tab_logo img {
		height: 20px;
	}
	.tab_btn {
		position: absolute;
		left: 20px;
		right: 80vw;
	}

	.tab_btn img {
		height: 20px;
	}
	.pd_header_list {
		width: 100%;
		height: 100vh;
		background: white;
		display: flex;
		flex-direction: column;
		top: 0px;
		left: 0;
		margin: 0 auto;
		z-index: 100;
		position: fixed;
	}
	.pd_header_list_btn {
		width: 20px;
		height: 20px;
		margin-top: 15px;
		margin-left: calc(100vw * 0.9);
	}
	.pd_header_list_btn img {
		width: 100%;
		height: 100%;
	}
	.tab_actionItem_line_2 {
		width: 100%;
		height: 1px;
		margin-top: 30px;
		/* margin-left: 10%; */
		margin-right: 10%;
		background: #e7e7e7;
	}
	.pd_header_list_1 {
		margin-top: 20px;
	}
	.tab_actionItem_pd {
		width: 90vw;
		height: 60px;
		margin-left: 5vw;
		display: flex;
		flex-direction: column;
	}
	.tab_actionItem_pd span {
		font-family: D-DIN;
		font-size: 18px;
		font-weight: normal;
		line-height: 18px;
		letter-spacing: 0em;
		font-variation-settings: "opsz" auto;
		font-feature-settings: "kern" on;
		color: #999999;
	}

	.pd_header_list_1 .tab_actionItem_line_3 {
		display: none;
	}

	.pd_header_list_1 .active span {
		font-family: DINPro-Bold;
		font-weight: bold;
		color: #333333;
	}

	.pd_header_list_1 .active .tab_actionItem_line_3 {
		display: flex;
		width: 60px;
		height: 2px;
		margin-top: -1px;
		margin-right: 10%;
		background: #333333;
	}

	.tab_actionItem_line_1 {
		width: 100%;
		height: 1px;
		margin-top: 20px;
		margin-right: 10%;
		background: #e7e7e7;
	}
}
</style>
<style scoped>
.tab_fun_language {
	align-content: center;
	border-radius: 6px;
	height: 30px;
	margin-left: 40px;
	padding-left: 10px;
	padding-right: 10px;
	background: rgba(255, 255, 255, 0.2);
	color: #ffffff;
	display: none;
}

.tab_fun_language_item {
}

.tab_fun_language_white {
	background: rgba(0, 0, 0, 0.2);
	color: black;
}
.top_Language_list {
	position: absolute; /* 设置绝对定位 */
	top: 60px; /* 将浮层放置在父元素下方 */
	right: 0px; /* 左对齐 */
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15); /* 阴影效果 */
	z-index: 1000; /* 确保浮层在其他元素之上 */
	padding-top: 20px;
	display: flex;
	position: absolute;
	justify-content: center;
	flex-direction: column;
	bottom: 160px;
	/* width: 90px; */
	height: 100px;
	border-radius: 10px;
	opacity: 1;
	background: #333333;
}
.top_luntan_list {
	width: 130px;
	text-align: center;
	align-content: center;
	position: absolute; /* 设置绝对定位 */
	top: 40px; /* 将浮层放置在父元素下方 */
	right: -20px; /* 左对齐 */
	z-index: 1000; /* 确保浮层在其他元素之上 */
	display: flex;
	position: absolute;
	justify-content: center;
	flex-direction: column;
	bottom: 160px;
	/* width: 90px; */
	height: 80px;
	border-radius: 10px;
	opacity: 1;
	background: #5034e7;
}

.top_Language_list_white {
	background: rgba(0, 0, 0, 0.7);
}
.top_Language_list_item {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 14px;
	font-weight: normal;
	line-height: 14px;
	color: #aaaaaa;
	margin-bottom: 20px;
	margin-left: 10px;
	margin-right: 10px;
}

.top_luntan_list_item {
	font-size: 14px;
	font-weight: bold;
	line-height: 16px;
	align-content: center;
	align-items: center;
	text-transform: uppercase;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	color: #ffffff;
	height: 40px;
	width: 130px;
	text-align: center;
}

.top_Language_list_item_white {
	color: white;
}
.top_Language_list_item:hover {
	font-weight: 600;
	color: #ffffff;
}

.top_Language_list_sel {
	color: #ffffff;
}
</style>
